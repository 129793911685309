<!-- 学习排行 -->
<template>
  <div>
    <div v-if="isNodata == false">
      <div class="ranking_wrap">
        <div class="content">
          <img src="../../../../assets/images/bg_ranking.png" alt="" />
          <div v-for="(v, i) in rankingList" :key="i">
            <div class="timeRanking timeRankingFirst" v-if="i == 0">
              {{ SecondToDate(v.studyTime) ? SecondToDate(v.studyTime) : "-" }}
            </div>
            <div class="timeRanking timeRankingSecond" v-if="i == 1">
              {{ SecondToDate(v.studyTime) ? SecondToDate(v.studyTime) : "-" }}
            </div>
            <div class="timeRanking timeRankingThird" v-if="i == 2">
              {{ SecondToDate(v.studyTime) ? SecondToDate(v.studyTime) : "-" }}
            </div>
            <dl class="personInformation firstPerson" v-if="i == 0">
              <dt>
                <img
                  :src="
                    v.src ||
                    require('../../../../assets/images/header_radius.png')
                  "
                  style="
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    border: 2px solid #ecca9f;
                  "
                />
              </dt>
              <dd>
                {{ v.userName }}
              </dd>
            </dl>
            <dl class="personInformation secondPerson" v-if="i == 1">
              <dt>
                <img
                  :src="
                    v.src ||
                    require('../../../../assets/images/header_radius.png')
                  "
                  style="
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    border: 2px solid #ecca9f;
                  "
                />
              </dt>
              <dd>
                {{ v.userName }}
              </dd>
            </dl>
            <dl class="personInformation thirdPerson" v-if="i == 2">
              <dt>
                <img
                  :src="
                    v.src ||
                    require('../../../../assets/images/header_radius.png')
                  "
                  style="
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    border: 2px solid #ecca9f;
                  "
                />
              </dt>
              <dd>
                {{ v.userName }}
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <div class="content_table">
        <el-table
          :data="rankingList"
          :header-cell-style="{
            background: '#f8f8f8',
            color: '#999',
            fontSize: '16px',
          }"
          :cell-style="{
            background: '#f8f8f8',
            fontSize: '16px',
          }"
          style="width: 100%"
        >
          <el-table-column label="排行" type="index" width="120">
            <template #default="scope">
              <span v-if="scope.$index == 0"
                ><img src="../../../../assets/images/icon_first.png" alt=""
              /></span>
              <span v-if="scope.$index == 1"
                ><img src="../../../../assets/images/icon_second.png" alt=""
              /></span>
              <span v-if="scope.$index == 2"
                ><img src="../../../../assets/images/icon_third.png" alt=""
              /></span>
              <span v-if="scope.$index >= 3" class="rankStyle">{{
                scope.$index + 1
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="用户名" width="180">
            <template #default="scope">
              <div style="display: flex">
                <img
                  :src="
                    scope.row.headImg ||
                    require('../../../../assets/images/header_radius.png')
                  "
                  style="
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    vertical-align: middle;
                  "
                  alt=""
                /><span
                  style="
                    margin-top: 4%;
                    font-weight: 400;
                    color: #333333;
                    font-size: 16px;
                    margin-left: 6px;
                  "
                  >{{ scope.row.userName }}</span
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column label="时间" width="280">
            <template #default="scope">
              <div style="font-weight: 400; color: #333333; font-size: 16px">
                学习<span style="color: #f04b4a">{{
                  SecondToDate(scope.row.studyTime)
                }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="最后一次学习时间" show-overflow-tooltip>
            <template #default="scope">
              <div style="font-weight: 400; color: #333333; font-size: 16px">
                {{ scope.row.lastStudyTime ? scope.row.lastStudyTime : "-" }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div v-else style="text-align: center; height: 66vh">
      <img
        src="@/assets/images/noData.png"
        alt=""
        style="margin-top: 12%; margin-bottom: 30px"
      />
      <p style="font-weight: 400; line-height: 22px; color: #bfbfbf">
        暂无数据
      </p>
    </div>
  </div>
</template>

<script>
import { GETSTUDYRANKE } from "@/server/details";

export default {
  components: {},
  data() {
    return {
      rankingList: [],
      isNodata: false,
    };
  },
  computed: {},
  created() {
    this.getList();
  },
  methods: {
    SecondToDate: function (msd) {
      var time = msd / 1000;
      if (null != time && "" != time) {
        if (time > 60 && time < 60 * 60) {
          time = parseInt(time / 60.0) + "分钟";
          // time = parseInt(time / 60.0) + "分钟" + parseInt((parseFloat(time / 60.0) - parseInt(time / 60.0)) * 60) + "秒";
        } else if (time >= 60 * 60 && time < 60 * 60 * 24) {
          time =
            parseInt(time / 3600.0) +
            "时" +
            parseInt(
              (parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60
            ) +
            "分";

          // time = parseInt(time / 3600.0) + "小时" + parseInt((parseFloat(time / 3600.0) -
          // parseInt(time / 3600.0)) * 60) + "分钟" +
          // parseInt((parseFloat((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) -
          // parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60)) * 60) + "秒";
        } else if (time >= 60 * 60 * 24) {
          let daay = parseInt(time / 3600.0 / 24);
          time =
            parseInt(time / 3600.0) +
            daay * 24 +
            "时" +
            parseInt(
              (parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60
            ) +
            "分";

          // time = parseInt(time / 3600.0/24) + "天" +parseInt((parseFloat(time / 3600.0/24)-
          // parseInt(time / 3600.0/24))*24) + "小时" + parseInt((parseFloat(time / 3600.0) -
          // parseInt(time / 3600.0)) * 60) + "分钟" +
          // parseInt((parseFloat((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) -
          // parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60)) * 60) + "秒";
        } else {
          time = "0时";
          //time = parseInt(time) + "秒";
        }
      } else {
        time = "0时";
      }
      return time;
    },
    getList() {
      GETSTUDYRANKE({ gradeId: this.$route.query.id }).then((res) => {
        console.log(res.data, "============");
        if (res.data.code == 0) {
          if (res.data.data.length == 0) {
            console.log("111");
            this.isNodata = true;
          }
          this.rankingList = res.data.data;
        }
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.ranking_wrap {
  width: 100%;
  height: 296px;
  background: #f8f8f8;
  .content {
    width: 540px;
    margin: 0 auto;
    padding-top: 130px;
    position: relative;
    .timeRanking {
      position: absolute;
      width: 110px;
      height: 33px;
      text-align: center;
      line-height: 33px;
      background: #ffffff;
      border-radius: 17px;
      font-weight: 400;
      font-size: 18px;
      color: #bc7b2a;
    }
    .timeRankingFirst {
      bottom: 74px;
      left: 214px;
    }
    .timeRankingSecond {
      bottom: 36px;
      left: 39px;
    }
    .timeRankingThird {
      bottom: 36px;
      right: 40px;
    }
    .personInformation {
      position: absolute;
    }
    .firstPerson {
      top: 14px;
      left: 42%;
      dd {
        width: 80px;
        height: 28px;
        text-align: center;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
      }
    }
    .secondPerson {
      top: 76px;
      left: 12%;
      dd {
        width: 60px;
        height: 28px;
        text-align: center;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
      }
    }
    .thirdPerson {
      top: 76px;
      right: 64px;
      dd {
        width: 60px;
        height: 28px;
        text-align: center;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
      }
    }
  }
}
.content_table {
  width: 100%;
  margin: 0 auto;
  margin-top: 5px;
  background: #f8f8f8;
  .rankStyle {
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    color: #999;
    display: inline-block;
    border-radius: 50%;
    background: #edeced;
  }
}
/deep/ .cell {
  font-size: 16px;
}
</style>
