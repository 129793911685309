<!--  -->
<template>
 <div>
    <Menu :menuTitle="title"/>
    <div class="ranking_wrap">
        <div class="content">
            <div class="details_content">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="学习排行" name="first">
                        <Studyranking/>
                    </el-tab-pane>
                    <el-tab-pane label="考试排行" name="second">
                        <Examinationranking/>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>   
 </div>
</template>

<script>
import Menu from '@/components/Menu'
import Studyranking from './componetsRanking/Studyranking.vue'
import Examinationranking from './componetsRanking/Examinationranking.vue'
export default {
 components:{
    Menu,
    Studyranking,
    Examinationranking
 },
 data () {
  return {
    title:'排行榜',
    activeName: 'first'
  }
 },
 created(){

 },
 methods:{
    handleClick(tab, event) {
    console.log(tab, event);
    }
 }
}

</script>

<style lang='scss' scoped>
.ranking_wrap {
    background-color: $white;
    padding-top: 100px;
    .content {
        width: 1200px;
        margin: 0 auto;
        padding-bottom: 80px;
    }
}  
/deep/ .el-tab-pane {
    width: 1200px;
    height: auto;
    margin-top: 20px;
}
/deep/ .el-tabs__item {
    font-size: 18px;
    color: $font_color_grey_3;
}

/deep/ .el-tabs__item.is-active {
    color: $theme_color;    
}
/deep/ .el-tabs__nav-wrap::after {
    display: none;
}

/deep/ .el-tabs__active-bar {
    display: none;
}
</style>
